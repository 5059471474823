import Main from '../containers/Main';

const NotFound = () => (
  <Main className="pt-24">
    <h1 className="text-4xl px-8 py-4 text-navy-400">
      Sorry the page you&apos;re looking for wasn&apos;t found.
    </h1>
  </Main>
);

export default NotFound;
